import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";

import "./App.css"
// import { onAuthStateChanged } from "firebase/auth";
// import { getDocs, query, where } from "firebase/firestore";
// import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import useToast from "../hooks/useToast";
// import { firebaseAuth, meetingsRef } from "../utils/firebaseConfig";
// import { generateMeetingID } from "../utils/generateMeetingId";

function App() {
  // const params = useParams();
  // const navigate = useNavigate();
  // const [createToast] = useToast();
  // const [isAllowed, setIsAllowed] = useState(false);
  const [user, setUser] = useState(undefined);
  // const [userLoaded, setUserLoaded] = useState(false);

  // onAuthStateChanged(firebaseAuth, (currentUser) => {
  //   if (currentUser) {
  //     setUser(currentUser);
  //   }
  //   setUserLoaded(true);
  // });



  // useEffect(() => {
  //   const getMeetingData = async () => {
  //     if (params.id && userLoaded) {
  //       const firestoreQuery = query(
  //         meetingsRef,
  //         where("meetingId", "==", params.id)
  //       );
  //       const fetchedMeetings = await getDocs(firestoreQuery);

  //       if (fetchedMeetings.docs.length) {
  //         const meeting = fetchedMeetings.docs[0].data();
  //         const isCreator = meeting.createdBy === user?.uid;
  //         if (meeting.meetingType === "1-on-1") {
  //           if (meeting.invitedUsers[0] === user?.uid || isCreator) {
  //             if (meeting.meetingDate === moment().format("L")) {
  //               setIsAllowed(true);
  //             } else if (
  //               moment(meeting.meetingDate).isBefore(moment().format("L"))
  //             ) {
  //               createToast({ title: "Meeting has ended.", type: "danger" });
  //               navigate(user ? "/" : "/login");
  //             } else if (moment(meeting.meetingDate).isAfter()) {
  //               createToast({
  //                 title: `Meeting is on ${meeting.meetingDate}`,
  //                 type: "warning",
  //               });
  //               navigate(user ? "/" : "/login");
  //             }
  //           } else navigate(user ? "/" : "/login");
  //         } else if (meeting.meetingType === "video-conference") {
  //           const index = meeting.invitedUsers.findIndex(
  //             (invitedUser: string) => invitedUser === user?.uid
  //           );
  //           if (index !== -1 || isCreator) {
  //             if (meeting.meetingDate === moment().format("L")) {
  //               setIsAllowed(true);
  //             } else if (
  //               moment(meeting.meetingDate).isBefore(moment().format("L"))
  //             ) {
  //               createToast({ title: "Meeting has ended.", type: "danger" });
  //               navigate(user ? "/" : "/login");
  //             } else if (moment(meeting.meetingDate).isAfter()) {
  //               createToast({
  //                 title: `Meeting is on ${meeting.meetingDate}`,
  //                 type: "warning",
  //               });
  //             }
  //           } else {
  //             createToast({
  //               title: `You are not invited to the meeting.`,
  //               type: "danger",
  //             });
  //             navigate(user ? "/" : "/login");
  //           }
  //         } else {
  //           setIsAllowed(true);
  //         }
  //       }
  //     }
  //   };
  //   getMeetingData();
  // }, [params.id, user, userLoaded, createToast, navigate]);

  useEffect(() => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 5; i++) {
      id += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setUser(id);
  }, [])

  const myMeeting = async (element) => {
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        parseInt("1995430512"),
        "376e295b543ff6157b5860d87458b953",
        "0yFKigd4",//meeting id
       user,
        user,
        // user?.uid ? user.uid : generateMeetingID(),
        // user?.displayName ? user.displayName : generateMeetingID()
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    zp?.joinRoom({
        container: element,
        // showPreJoinView: true,
        // showScreenSharingButton: true,
        // showRemoveUserButton: true,
        // showRoomTimer: true,
        // showScreenSharingButton: false,
        // maxUsers: 50,
        sharedLinks: [
            {
                name: "Wabinar YouGutMap",
                url: "https://webinar.yourgutmap.co.uk",
            },
        ],
        scenario: {
            mode: "LiveStreaming",
            config: {
                role: "Audience",
            }
        },
    });


    //   {
    //     turnOnMicrophoneWhenJoining: true,
    //     turnOnCameraWhenJoining: true,
    //     showMyCameraToggleButton: true,
    //     showMyMicrophoneToggleButton: true,
    //     showAudioVideoSettingsButton: true,
    //     showScreenSharingButton: true,
    //     showTextChat: true,
    //     showUserList: true,
    //     maxUsers: 50,
    //     layout: "Auto",
    //     showLayoutButton: true,
    //     scenario: {
    //       mode: "VideoConference",
    //       config: {
    //         role: "Host",
    //     },
    //   },
    // }

    // ....
    // zp.joinRoom({
    //   // ...,
    //   onJoinRoom: () => {
    //     // Add your custom logic
    //   },
    //   onLeaveRoom: () => {
    //     // Add your custom logic
    //   }
    //   // ...
    // });
};








  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div className="navbar"><h1>logo</h1> <button >test</button></div>
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ width: "100%", height: "90vh" }}
      ></div>
    </div>
  )
}


export default App;